import { Alert, Button, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useAuthenticate, useAuthenticateWithSSO } from "../api/api";
import { useGetUserByEmail } from "../api/api.get";
import ErrorPanel from "../components/error.panel.component";
import { useLayoutContext } from "../providers/layout.provider";
import { IStatusMsg } from "../types/types";
import { getRecaptchaKey, isRecaptchaEnabled } from "../utils/app";
import {
  EXHUT_HUB_LOCAL_STORAGE_TOKEN,
  EXHUT_REDIRECT,
  ROUTES,
} from "../utils/constants";
import { downloadAndLoadGSIScript } from "../utils/lib.helper";
import ReCAPTCHA from "react-google-recaptcha";

export default function RegisterPage() {
  const { isMobile } = useLayoutContext();
  const { mutateAsync: loginAsync, isLoading, isError } = useAuthenticate();
  const { mutateAsync: loginWithSSO } = useAuthenticateWithSSO();
  const [showPasswordMode, setShowPasswordMode] = useState<boolean>(false);

  const navigate = useNavigate();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg | undefined>();
  const [email, setEmail] = useState<string>();
  const [mode, setMode] = useState<"login" | "register" | null>(null);
  const [recaptchaVerified, setRecaptchaVerified] = useState(
    !isRecaptchaEnabled
  );

  const { data: usersData, isError: isGetUserError } = useGetUserByEmail(email);

  const [searchParams, setSearchParams] = useSearchParams();

  const isLogout = new URLSearchParams(searchParams).get("logout");

  useEffect(() => {
    if (usersData) {
      if (usersData?.length > 0) {
        setMode("login");
      } else {
        setMode("register");
      }
    } else {
      setMode(null);
    }
  }, [usersData, isGetUserError]);

  useEffect(() => {
    if (isLogout) {
      searchParams.delete("logout");
      setSearchParams(searchParams);

      setStatusMsg({ type: "error", text: "Please sign in." });
    }
  }, [isLogout]);

  useEffect(() => {
    downloadAndLoadGSIScript().then(() => {
      (window as any).google.accounts.id.initialize({
        client_id: process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID,
        callback: (response: any) => {
          loginWithSSO({ token: response.credential, type: "google" })
            .then((resp: any) => {
              localStorage.setItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN, resp.sign);
              const redirect = localStorage.getItem(EXHUT_REDIRECT);
              localStorage.removeItem(EXHUT_REDIRECT);
              navigate(redirect ?? ROUTES.HOME);
            })
            .catch(() => {
              setStatusMsg({
                type: "error",
                text: "Please use your email to signin.",
              });
            });
        },
      });
      (window as any).google.accounts.id.renderButton(
        document.querySelector(".sso-signin"),
        { theme: "dark", size: "medium" } // customization attributes
      );
    });
  }, [loginWithSSO]);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password."),
      email: Yup.string().required("Please enter email"),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      loginAsync({ email: values.email, password: values.password })
        .then((resp) => {
          localStorage.setItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN, resp.sign);
          const redirect = localStorage.getItem(EXHUT_REDIRECT);
          if (redirect && redirect !== "/login") {
            navigate(redirect);
          } else if (resp.admin === true) {
            navigate(ROUTES.ADMIN);
          } else {
            navigate(ROUTES.HOME);
          }
        })
        .catch((e) => {
          setStatusMsg({
            type: "error",
            text: "The email or password you have entered is incorrect. Please try again.",
          });
        });
    },
  });

  const setLoginReady = (e: any) => {
    // console.log("Ready recaptch", )
    setRecaptchaVerified(true);
  };

  return (
    <div className="body">
      <div className={`mb-80 ${isMobile ? "mx-20" : "mx-80"}`}>
        <div className={isMobile ? "mt-40 divColumn" : "divFlex p-40"}>
          <div className="flex-1 divCenterAlign divColumn">
            <h2>We help achieve your goals</h2>

            <img src="/assets/rubiks.png" style={{ width: "50%" }} />
          </div>

          <div className="flex-1 divCenterAlign divColumn">
            <div className="my-40">
              <h2>Login</h2>
            </div>

            <form
              action="submit"
              onSubmit={formik.handleSubmit}
              className="divColumn pb-40"
            >
              <>
                <div style={{ height: 100 }} className="divColumn mb-0">
                  <input
                    id="email"
                    placeholder="Email..."
                    type="text"
                    className="bordered"
                    style={{ width: 300 }}
                    {...formik.getFieldProps("email")}
                  />
                  <div style={styles.formRowMin}>
                    <ErrorPanel message={formik.errors.email} />
                  </div>
                </div>
              </>

              <>
                <div
                  style={{ position: "relative" }}
                  className="divColumn mb-0"
                >
                  <input
                    id="password"
                    placeholder="Password..."
                    type={showPasswordMode ? "text" : "password"}
                    autoFocus={true}
                    className="bordered"
                    style={{ width: 300 }}
                    {...formik.getFieldProps("password")}
                  />
                  <div style={{ position: "absolute", right: 0, marginTop: 5 }}>
                    <Button
                      style={{ fontSize: 10 }}
                      onClick={() => setShowPasswordMode(!showPasswordMode)}
                    >
                      Show
                    </Button>
                  </div>

                  <div style={styles.formRowMin}>
                    <ErrorPanel message={formik.errors.password} />
                  </div>
                </div>

                {isRecaptchaEnabled && (
                  <div style={{}} className="mx-20 my-20">
                    <ReCAPTCHA
                      sitekey={getRecaptchaKey()}
                      onChange={setLoginReady}
                    />
                  </div>
                )}

                <div style={{}} className="mx-20 my-40">
                  <input
                    type="submit"
                    disabled={isLoading || !recaptchaVerified}
                    value={"Sign In"}
                    className="bigButtonGreen"
                  />
                </div>
                <div className="divCenterAlign my-20">
                  <span className="sso-signin "></span>
                </div>

                <div className="divCenterAlign my-20">
                  <Link to={ROUTES.forgotPassword}>Forgot Password</Link>
                </div>
              </>
            </form>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!!statusMsg}
          autoHideDuration={3000}
          onClose={() => setStatusMsg(undefined)}
          message={statusMsg?.text}
          key={1}
        >
          <Alert severity="error">{statusMsg?.text}</Alert>
        </Snackbar>
      </div>
    </div>
  );
}

const styles = {
  formRowMin: {
    display: "flex",
    margin: "5px 10px",
  },
  formRow: {
    marginTop: 30,
    display: "flex",
  },
  formColumn: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
};
