import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WorkIcon from "@mui/icons-material/Work";
import { Link } from "react-router-dom";
import { useGetProfile } from "../api/api.get";
import TermsConditionsComponent from "../components/other/TermsConditionsComponent";
import FeedbackComponent from "../components/other/feedback.component";
import Tile from "../components/tile.component";
import { useAuthContext } from "../providers/auth.provider";
import { useLayoutContext } from "../providers/layout.provider";
import PaidIcon from "@mui/icons-material/Paid";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LanguageIcon from "@mui/icons-material/Language";
import { Divider } from "@mui/material";
import RewardsComponent from "../components/rewards.component";

export default function HomePage() {
  const { isMobile } = useLayoutContext();

  return (
    <div className={`mb-160`} id="home-body">
      <ProfileBadge />

      <RewardsComponent />

      <div className={isMobile ? "divColumn divCenterAlign" : "divCenterAlign"}>
        <Tile
          id="home-page-account-tile"
          title={"Settings"}
          subtitle={""}
          route="/settings"
          iconComp={() => (
            <AccountCircleIcon color="secondary" fontSize="large" />
          )}
        />

        <Tile
          id="home-page-faq-tile"
          title="Support"
          subtitle="Submit your question"
          route="/support"
          iconComp={() => (
            <img
              src={"/assets/icons/question.png"}
              className="iconSizeLarge"
              alt="faq"
            />
          )}
        />
      </div>

      <div
        className={
          isMobile ? "divColumn divCenterAlign" : "divCenterAlign mb-0"
        }
      >
        <Tile
          id="home-page-faq-tile"
          title="FAQ"
          subtitle="Find answers to all your questions."
          route="/home/faq"
          iconComp={() => (
            <img
              src={"/assets/icons/question.png"}
              className="iconSizeLarge"
              alt="faq"
            />
          )}
        />
      </div>

      <div className={isMobile ? "divColumn divCenterAlign" : "divCenterAlign"}>
        <Tile
          id="home-page-jobs-tile"
          title="ExpenseHut"
          subtitle={"A personal finance dashboard."}
          external="https://www.expensehut.com"
          iconComp={() => <PaidIcon fontSize="large" color="warning" />}
        />

        <Tile
          id="home-page-company-rating-tile"
          subtitle={"Split bills, receipts between friends or family."}
          title={"Wallet"}
          external="https://wallet.expensehut.com"
          iconComp={() => <CreditCardIcon color="success" fontSize="large" />}
        />
      </div>

      <div className={isMobile ? "divColumn divCenterAlign" : "divCenterAlign"}>
        <Tile
          id="home-page-jobs-tile"
          title="Recruit"
          subtitle={"A next-gen job search platform."}
          external="https://www.expensehut.com"
          iconComp={() => <WorkIcon fontSize="large" color="primary" />}
        />

        <Tile
          id="home-page-company-rating-tile"
          subtitle={"Author articles & publish for free!"}
          title={"Blogs"}
          external="https://blogs.expensehut.com"
          iconComp={() => <LanguageIcon color="success" fontSize="large" />}
        />
      </div>

      <FeedbackComponent />

      <TermsConditionsComponent />
    </div>
  );
}

function ProfileBadge() {
  const { user } = useAuthContext();
  const { data: profile } = useGetProfile();

  return (
    <div className="divSpreadEven mt-40 mb-20">
      <div className="mx-20" style={{ width: "250px", fontWeight: "bold" }}>
        <div className="">
          Hi,
          <Link to="/settings/profile" className="mx-5">
            {profile?.firstName ?? "Guest"}
          </Link>
        </div>
      </div>

      <div>
        <Link to={"/login?logout=1"}>Logout</Link>
      </div>
    </div>
  );
}
