import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLayoutContext } from "../../../providers/layout.provider";
import { IUser } from "../../../types/types";
import "./leftpane.component.css";
import { Link } from "react-router-dom";

export default function LeftPaneItems({
  onPostClick,
  anchorEl,
  open,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  onPostClick: (index: number) => void;
}) {
  const { isMobile } = useLayoutContext();

  return (
    <Menu
      id="basic-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={onPostClick}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <>
        <Link to="/">
          <MenuItem onClick={() => onPostClick(1)}>Home</MenuItem>
        </Link>

        <hr />
        <Link to="/about">
          <MenuItem onClick={() => onPostClick(2)}> About Us</MenuItem>
        </Link>
        <hr />

        <Link to="/products">
          <MenuItem onClick={() => onPostClick(2)}>Products</MenuItem>
        </Link>
        <hr />

        <Link to="/register">
          <MenuItem onClick={() => onPostClick(2)}>Register</MenuItem>
        </Link>
        <hr />

        <a href="https://help.expensehut.com">
          <MenuItem onClick={() => onPostClick(2)}>Help</MenuItem>
        </a>
        <hr />

        <Link to="/contact">
          <MenuItem onClick={() => onPostClick(2)}>Contact Us</MenuItem>
        </Link>
      </>
    </Menu>
  );
}
