import { Link, useNavigate } from "react-router-dom";
import LogoComponent from "../components/leftpane/items/logo.component";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import LeftPaneItems from "./leftpane/items/leftpane.items";
import { useLayoutContext } from "../providers/layout.provider";

export default function MobileHeader() {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const navigate = useNavigate();
  const { isMobile } = useLayoutContext();

  const handleClick = (index: number) => {
    setAnchorEl(null);
    if (index === 0) {
      window.location.href = "https://www.expensehut.com";
    } else if (index === 1) {
      navigate("/settings/profile");
    } else if (index === 2) {
      navigate("/settings/change-password");
    } else if (index === 3) {
      // signout();
    }
  };

  return (
    <div className="bg-purple">
      <div className="divSpread divAlignItemsOnly body">
        <LogoComponent />

        {isMobile && (
          <>
            {" "}
            <OptionsMenu
              id="toggle-options"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <LeftPaneItems
              anchorEl={anchorEl}
              open={!!anchorEl}
              onPostClick={handleClick}
            />
          </>
        )}

        {!isMobile && (
          <div className="divAlignItemsOnly">
            <Link
              className="mx-20"
              to="/"
              style={{ color: "white", textDecoration: "none" }}
            >
              Home
            </Link>
            <Link
              className="mx-20"
              to="/about"
              style={{ color: "white", textDecoration: "none" }}
            >
              About Us
            </Link>

            <Link
              className="mx-20"
              to="/products"
              style={{ color: "white", textDecoration: "none" }}
            >
              Products
            </Link>

            <Link
              to="/signup"
              className="mx-20"
              style={{ color: "white", textDecoration: "none" }}
            >
              Register{" "}
            </Link>
            <a
              className="mx-20"
              target="_blank"
              rel="noreferrer"
              href="https://help.expensehut.com"
              style={{ color: "white", textDecoration: "none" }}
            >
              Help
            </a>
            <Link
              className="mx-20"
              to="/contact"
              style={{ color: "white", textDecoration: "none" }}
            >
              Contact Us
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export function OptionsMenu(props: React.HTMLProps<{}>) {
  return (
    <div style={{ height: 40, marginBottom: 40 }}>
      <IconButton
        color="default"
        aria-label="open drawer"
        onClick={props.onClick}
        edge="start"
      >
        <MenuIcon htmlColor="white" />
      </IconButton>
    </div>
  );
}
