import { Alert, Button } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useAuthenticateWithSSO, useRegisterMutation } from "../api/api";
import ErrorPanel from "../components/error.panel.component";
import SuccessBanner from "../components/success-banner.component";
import { useLayoutContext } from "../providers/layout.provider";
import { IStatusMsg } from "../types/types";
import { getRecaptchaKey, isRecaptchaEnabled } from "../utils/app";
import {
  EXHUT_HUB_LOCAL_STORAGE_TOKEN,
  EXHUT_REDIRECT,
  ROUTES,
} from "../utils/constants";
import { downloadAndLoadGSIScript } from "../utils/lib.helper";

export default function RegisterPage() {
  const { isMobile } = useLayoutContext();
  const { mutateAsync: loginWithSSO } = useAuthenticateWithSSO();
  const { mutateAsync: registerAsync, isLoading: isRegisterLoading } =
    useRegisterMutation();
  const [showPasswordMode, setShowPasswordMode] = useState<boolean>(false);

  const navigate = useNavigate();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg | undefined>();
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(
    !isRecaptchaEnabled
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const isLogout = new URLSearchParams(searchParams).get("logout");

  useEffect(() => {
    if (isLogout) {
      searchParams.delete("logout");
      setSearchParams(searchParams);

      setStatusMsg({ type: "error", text: "Please sign in." });
    }
  }, [isLogout]);

  useEffect(() => {
    downloadAndLoadGSIScript().then(() => {
      (window as any).google.accounts.id.initialize({
        client_id: process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID,
        callback: (response: any) => {
          loginWithSSO({ token: response.credential, type: "google" })
            .then((resp: any) => {
              localStorage.setItem(EXHUT_HUB_LOCAL_STORAGE_TOKEN, resp.sign);
              const redirect = localStorage.getItem(EXHUT_REDIRECT);
              localStorage.removeItem(EXHUT_REDIRECT);
              navigate(redirect ?? ROUTES.HOME);
            })
            .catch(() => {
              setStatusMsg({
                type: "error",
                text: "Please use your email to signin.",
              });
            });
        },
      });
      (window as any).google.accounts.id.renderButton(
        document.querySelector(".sso-signin"),
        { theme: "dark", size: "medium" } // customization attributes
      );
    });
  }, [loginWithSSO]);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password."),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
          "Please enter valid email address"
        )
        .required("Please enter email."),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      localStorage.setItem(EXHUT_REDIRECT, "/login");
      registerAsync({ email: values.email, password: values.password })
        .then(() => {
          setRegisterSuccess(true);
        })
        .catch((err) => {
          setStatusMsg({
            type: "error",
            text: err.response.data.message ?? "Whoops! Something went wrong.",
          });
        });
    },
  });

  useEffect(() => {
    if (!!statusMsg) {
      setStatusMsg(undefined);
    }
  }, [formik.values]);

  const setLoginReady = (e: any) => {
    // console.log("Ready recaptch", )
    setRecaptchaVerified(true);
  };

  return (
    <div className="body">
      <div className={`mb-80 ${isMobile ? "mx-20" : "mx-80"}`}>
        <Header isMobile={isMobile} />

        {!!statusMsg && (
          <div className="divCenterAlign">
            <Alert severity={statusMsg?.type}>{statusMsg?.text}</Alert>
          </div>
        )}

        {registerSuccess && (
          <>
            <SuccessBanner msg="We have emailed you a verification link to your email. Please use it to proceed" />
            <div className="my-20"></div>
          </>
        )}

        {!registerSuccess && (
          <form
            action="submit"
            onSubmit={formik.handleSubmit}
            className="divColumn divCenterAlign px-20 pb-40"
          >
            <>
              <div style={{ height: 100 }} className="divColumn mt-80">
                <input
                  id="email"
                  placeholder="Email..."
                  type="text"
                  className="bordered"
                  style={{ width: 300 }}
                  {...formik.getFieldProps("email")}
                />
                <div style={styles.formRowMin}>
                  <ErrorPanel message={formik.errors.email} />
                </div>
              </div>

              <div style={{ position: "relative" }} className="divColumn mb-0">
                <input
                  id="password"
                  placeholder="Password..."
                  type={showPasswordMode ? "text" : "password"}
                  autoFocus={true}
                  className="bordered"
                  style={{ width: 300 }}
                  {...formik.getFieldProps("password")}
                />
                <div style={{ position: "absolute", right: 0, marginTop: 5 }}>
                  <Button
                    style={{ fontSize: 10 }}
                    onClick={() => setShowPasswordMode(!showPasswordMode)}
                  >
                    Show
                  </Button>
                </div>

                <div style={styles.formRowMin}>
                  <ErrorPanel message={formik.errors.password} />
                </div>
              </div>

              {isRecaptchaEnabled && (
                <div style={{}} className="mx-20 my-20">
                  <ReCAPTCHA
                    sitekey={getRecaptchaKey()}
                    onChange={setLoginReady}
                  />
                </div>
              )}

              <div style={{}} className="mx-20 mt-40">
                <input
                  type="submit"
                  disabled={isRegisterLoading || !recaptchaVerified}
                  value={"Signup"}
                  className="bigButtonGreen"
                />
              </div>
            </>
          </form>
        )}

        <div className="divCenterAlign m-20">
          <span className="sso-signin "></span>
        </div>

        <div className="divCenterAlign my-20">
          <Link to={ROUTES.login}> Already have an account? </Link>
        </div>
      </div>
    </div>
  );
}

const Header = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <div
      className={`${
        isMobile ? "divColumn divCenterAlign" : "divFlex"
      } mt-40 mb-40`}
    >
      <div style={{ width: "20%" }}></div>
      <div
        className="divCenterAlign mb-0"
        style={{ width: "60%", marginBottom: -20 }}
      >
        <h1>Great products are driven by customers like you!</h1>
      </div>
    </div>
  );
};

const styles = {
  formRowMin: {
    display: "flex",
    margin: "0px 10px",
  },
  formRow: {
    marginTop: 30,
    display: "flex",
  },
  formColumn: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
};
