import { BrowserRouter, Route, Routes } from "react-router-dom";
import FooterComponent from "./components/footer.component";
import MobileHeader from "./components/mobile.header.component";
import AboutPage from "./pages/about.page";
import ContactUs from "./pages/contact-us.page";
import ForgotPassword from "./pages/forgot-password.page";
import LandingPage from "./pages/landing.page";
import RegisterPage from "./pages/register.page";
import LoginPage from "./pages/login.page";
import ProductsPage from "./pages/products.page";
import { AuthenticatedRoutes } from "./routes";
import "./styles/button.styles.css";

const Main = () => {
  return (
    <div className={`app`}>
      <BrowserRouter>
        {/*
        Do not move auth provider from here.
        The Mobile header component needs this.
        */}
        <MobileHeader />
        <Routes>
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about*" element={<AboutPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<AuthenticatedRoutes />} />
        </Routes>
        <FooterComponent />
      </BrowserRouter>
    </div>
  );
};

export default Main;
