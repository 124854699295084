import React from "react";
import { useLayoutContext } from "../providers/layout.provider";

export default function AboutPage() {
  const { isMobile } = useLayoutContext();

  return (
    <div className="body">
      <div className="">
        <div className={`p-40 ${isMobile ? "divColumn" : "divFlex"}`}>
          <iframe
            src="https://www.blogs.expensehut.com/articles/6249da883ca033188a7e41a4/published/body?no_header=true"
            width="100%"
            style={{ borderWidth: 0 }}
            height="2800px"
          />
        </div>
      </div>
    </div>
  );
}
